import React, { useRef, useState } from "react";
import _translate from "../Globals/translation";
import { API_URL } from "../Globals/globalVariables";
import { fixedEncodeURIComponent } from "../Globals/globalFunctions";
import cookiehandler from "../../cookies";

interface AccountManagerEntryParameters {
    accountID: number,
    sessionTokenAuth: string,
    accountTag: string,
    key: number,
    reloadAccounts: () => void,
}
interface Account {
    username: string,
    password: string,
    token: string,
    apikey: string,
    id: number,
    accountType: "main" | "sub",
    tag: string,
    oadc: string
}

const AccountManagerEntry: React.FC<AccountManagerEntryParameters> = ({accountID, accountTag, sessionTokenAuth, reloadAccounts}) => {
    const [account, setAccount] = useState<Account>({username: "", password: "", token: "", apikey: "", id: 0, accountType: "sub", tag: "", oadc: ""});
    const [showAccount, setShowAccount] = useState<boolean>(false);
    const [changes, setChanges] = useState<boolean>(false);
    // password visibility and edit
    const passwordInputtRef = useRef<HTMLInputElement>(null);
    const passwordButtonRef = useRef<HTMLButtonElement>(null);
    
    // tag edit
    const tagInputRef = useRef<HTMLInputElement>(null);

    // oadc
    const oadcInputRef = useRef<HTMLInputElement>(null);

    const getAccountInfo = async () => {
        try {
            let response = await fetch(API_URL + '/subuser/'+accountID,  {method:'GET', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                
            } else {
                let data = await response.json();
                setAccount(
                    {
                        username: data.user.username ? data.user.username : "",
                        password: data.user.password ? data.user.password : "",
                        token: data.user.token ? data.user.token : "",
                        apikey: data.user.apikey ? data.user.apikey : "",
                        id: data.user.id ? data.user.id : 0,
                        accountType: data.user.accountType ? data.user.accountType : "sub",
                        tag: data.user.tag ? data.user.tag : "",
                        oadc: data.user.oadc ? data.user.oadc : "",
                    }
                );   
            }    
        } catch (error) {
            console.warn("No Account found");
        }
    }
    
    const expandAccount = () => {
        setShowAccount(!showAccount);
        getAccountInfo();
    }

    const onInputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccount({...account, password: e.target.value});
        setChanges(true);
    }
    const onPasswordRandomize = () => {
        const _pw = generateRandomPassword();
        setAccount({...account, password: _pw});
        passwordInputtRef.current!.value = _pw;
        setChanges(true);
    }
    const onInputTag = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccount({...account, tag: e.target.value});
        setChanges(true);
    }

    const cancelEdit = () => {
        setChanges(false);
        getAccountInfo();
    }

    const onInputOadc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccount({...account, oadc: e.target.value})
        setChanges(true);
    }

    const togglePasswordVisibility = () => {
        if(passwordInputtRef.current !== null && passwordButtonRef.current !== null){
            if(passwordInputtRef.current.type === "password"){
                passwordInputtRef.current.type = "text";
                passwordButtonRef.current.textContent = _translate("Hide").toString();
            } else {
                passwordInputtRef.current.type = "password";
                passwordButtonRef.current.textContent = _translate("Show").toString();
            }
        }
    }

    const generateRandomPassword = () => {
        const length = 12; 
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";
        const allChars = lowercase + uppercase + numbers + specialChars;
        const getRandomChar = (chars: string) => chars[Math.floor(Math.random() * chars.length)];
    
        let password = "";
        password += getRandomChar(lowercase); // Ensure at least one lowercase letter
        password += getRandomChar(uppercase); // Ensure at least one uppercase letter
        password += getRandomChar(numbers); // Ensure at least one number
        password += getRandomChar(specialChars); // Ensure at least one special character
    
        for (let i = 4; i < length; i++) {
            password += getRandomChar(allChars);
        }
        return password.split('').sort(() => 0.5 - Math.random()).join(''); // Shuffle the password
    };

    const saveEdit = async () => {
        // check if the tag is empty
        var url_to_fetch = API_URL + '/subuser/'+accountID;

        if(account.tag === "" || account.tag === undefined || account.tag === null) {
            window.alert(_translate("Tag can not be empty"));
            return;
        }
        url_to_fetch += "?tag="+fixedEncodeURIComponent(account.tag);

        // check if the oadc is empty
        if (account.oadc === "" || account.oadc === undefined || account.oadc === null) {
            window.alert(_translate("Sender identification can not be empty"));
            return;
        }
        url_to_fetch += "&oadc="+fixedEncodeURIComponent(account.oadc);
        // check if the password is empty
        if(!(account.password === "" || account.password === undefined || account.password === null)) {
            // when the password is not empty, check if the password is valid
            if(account.password.length < 8 || account.password.length > 64 || !account.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,64}$/)){
                window.alert(_translate("Password must contain at least 8 characters, one uppercase letter and one special character"));
                return;
            }
            url_to_fetch += "&password="+fixedEncodeURIComponent(account.password);
        }

        // if((account.password === "" || account.password === undefined || account.password === null) &&
        //     (account.password.length < 8 || account.password.length > 64) ||
        //     // check if there is a uppercase letter, a lowercase letter and a special character
        //     !account.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,64}$/)) {
        //         window.alert(_translate("Password can not be empty. A password must contain at least 8 characters, one uppercase letter and one special character"));
        //         return;
        // }        
        // sending the request
        // encode the password and tag so that the url is valid and the request can be send
        try {
            // let response = await fetch(API_URL + '/subuser/'+accountID +"?tag="+fixedEncodeURIComponent(account.tag)+"&password="+fixedEncodeURIComponent(account.password)+"&oadc="+fixedEncodeURIComponent(account.oadc), 
            let response = await fetch(url_to_fetch, 
                {method:'PUT', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString(), 'Content-Type': 'application/json'}});
            if(!response.ok){
                console.warn("Saving Account failed");
                let data = await response.json();
                window.alert("Saving Account failed! Errorcode: " + data.error);
            } else {
                // console.log("Account saved");
                setChanges(false);
                window.alert(_translate("Account saved"));
                reloadAccounts();
            }
        } catch (error) {
            console.warn("Saving Account failed");
            window.alert("Saving Account failed!");
        }
    }
    const deleteAccount = async () => {
        // confirm dialog -> if not confirmed return and do nothing
        if(!window.confirm(_translate("Are you sure you want to delete this account?").toString())) return;
        // sending the request
        try {
            let response = await fetch(API_URL + '/subuser/'+accountID,  {method:'DELETE', 
                headers: {'Authorization': 'Bearer ' + sessionTokenAuth.toString()}});
            if(!response.ok){
                console.warn("Deleting Account failed");
                let data = await response.json();
                window.alert("Deleting Account failed! Errorcode: " + data.error);
            } else {
                // console.log("Account deleted");
                window.alert(_translate("Account deleted"));
                // reload the accounts
                reloadAccounts();
            }
        } catch (error) {
            console.warn("Deleting Account failed");
            window.alert("Deleting Account failed!");
        }
    }

    const subaccountLogin = async () => {
        // confirm dialog -> if not confirmed return and do nothing
        if(!window.confirm(_translate("Are you sure you want to login with this account?").toString())) return;
        // reconfigure the cookies
        // delete the old cookies
        cookiehandler.delete_cookie("apikey", "/", window.location.hostname);
        cookiehandler.delete_cookie("main-apikey", "/", window.location.hostname);
        cookiehandler.delete_cookie("sessionTokenAuth", "/", window.location.hostname);
        // set the new cookies
        cookiehandler.setCookie("apikey", account.token, "/", window.location.hostname);
        cookiehandler.setCookie("sessionTokenAuth", sessionTokenAuth, "/", window.location.hostname);
        
        // reload the page
        window.location.href = "/";
    }
    const copyToClipboard= (item: string) => {
        navigator.clipboard.writeText(item);
    }

    return <>
        <div className="profileContainer" >
            <span className="textspan profileName" onClick={expandAccount}>{accountTag}</span>
            <button className="button profileButton" onClick={expandAccount}>{showAccount ? "-" : "+"}</button>
            { showAccount ? <>
                <div className="profileInfo">
                    <div className="profileInputContainer profileInputContainerTag"> 
                        <span className="textspan profileInfoText profileInfoTextTag">{_translate("Tag")}</span>    
                        <input className="input profileInfoInput profileInfoInputTag" onInput={(e: React.ChangeEvent<HTMLInputElement>) => onInputTag(e)} ref={tagInputRef} type="text" value={account.tag.toString()} />
                    </div>
                    <div className="profileInputContainer profileInputContainerOadc"> 
                        <span className="textspan profileInfoText profileInfoTextOadc">{_translate("Sender identification")}</span>    
                        <input title={_translate("Sender Identification that will be displayed for the reciever.").toString()} className="input profileInfoInput profileInfoInputOadc" onInput={(e: React.ChangeEvent<HTMLInputElement>) => onInputOadc(e)} ref={oadcInputRef} type="text" value={account.oadc.toString()} />
                    </div>
                    <div className="profileInputContainer profileInputContainerAccountType">
                        <span className="textspan profileInfoText profileInfoTextusername">{_translate("Username")}</span>
                        <input  title={_translate("Username of the subaccount. This can not be changed.").toString()} className="input profileInfoInput profileInfoInputUsername" type="text" disabled value={account.username.toString()} />
                        <button title={_translate("copy").toString()} className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(account.username)}>&#128203;</button>
                    </div>
                    <div className="profileInputContainer profileInputContainerPassword">
                        <span className="textspan profileInfoText profileInfoTextPassword">{_translate("Password")}</span>
                        {/* <input className="input profileInfoInput profileInfoInputPassword" onInput={(e: React.ChangeEvent< HTMLInputElement>) => onInputPassword(e)} ref={passwordInputtRef} type="password" value={account.password.toString()} /> */}
                        <input title={_translate("Here you can change the password of the subaccount. The current password will not be displayed here and when changed will be overwritten.").toString()} className="input profileInfoInput profileInfoInputPassword" onInput={(e: React.ChangeEvent< HTMLInputElement>) => onInputPassword(e)} ref={passwordInputtRef} type="text" />
                        <button className="button button-twoFA-content button-twoFA-randomizepw" onClick={onPasswordRandomize} title={_translate("Generate a random password").toString()}>&#x1F3B2;</button>
                        <button title={_translate("copy").toString()} className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(account.password)}>&#128203;</button>
                        {/* <button className="button profileInfoButton profileInfoButtonPassword" ref={passwordButtonRef} onClick={togglePasswordVisibility}>{_translate("Show")}</button> */}
                    </div>
                    <div className="profileInputContainer profileInputContainerToken">
                        <span className="textspan profileInfoText profileInfoTextToken">{_translate("API-Key")}</span>
                        <input title={_translate("API Keys can be used to send Messages using this specific account. This key can not be changed and is individualy generated for each subaccount.").toString()} className="input profileInfoInput profileInfoInputToken" disabled type="text" value={account.apikey.toString()} />
                        <button title={_translate("copy").toString()} className="button button-twoFA-content-twoFA-sitekey" onClick={()=>copyToClipboard(account.apikey)}>&#128203;</button>
                    </div>
                    <div className="profileInputContainer profileEdittContaier">
                        {changes ? <><button className="button profileInfoButton profileInfoButtonSave" onClick={saveEdit}>{_translate("Save")}</button>
                        <button className="button profileInfoButton profileInfoButtonCancel" onClick={cancelEdit}>{_translate("reset")}</button></>: <></>}
                        
                        <button className="button profileInfoButton profileInfoButtonDelete" onClick={deleteAccount}>{_translate("Delete")}</button>
                        {
                            account.token !== undefined && account.token !== null && account.token !== "" ? 
                            <button className="button profileInfoButton profileInfoButtonLogin" onClick={subaccountLogin}>{_translate("Login with subaccount")}</button>
                            : <></>
                        }
                    </div>
                </div>
            </> : <></> }
        </div>
    </>;
}

export default AccountManagerEntry;