import cookiehandler from "../../cookies";

interface LanguageType {
    English: String;
    German: String;
}

var defaultLanguage = "Ger";

export const convertFloatSeperation = (input: string): Number => {
    if(defaultLanguage === "Ger"){
        return parseFloat(input.replace(".","").replace(",","."));
    } else if(defaultLanguage === "Eng"){
        return parseFloat(input.replace(",",""));
    }
    return 0;
}

export const convertFloatSeperationToString = (input: string): String => {
    if(defaultLanguage === "Ger"){
        return input.toString().replace(".",",");
    } else if(defaultLanguage === "Eng"){
        return input.toString();
    }
    return input;
}

const LanguagesList: Array<LanguageType> = [
        /* Register.tsx */
        {English: "Register", German: "Registrieren"},
        {English: "Full name", German: "Voller Name"},
        {English: "E-Mail / Username", German: "E-Mail / Benutzername"},
        {English: "Password - min. 8 characters", German: "Passwort - min. 8 Zeichen"},
        {English: "Register failed - check your credentials.", German: "Registrierung fehlgeschlagen - bitte Eingabedaten überprüfen."},
        {English: "Please enter and verify your phone number", German: "Bitte geben Sie Ihre Telefonnummer ein und verifizieren Sie diese"},
        {English: "Please enter a password with at least 8 characters", German: "Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein"},
        {English: "Please enter a username", German: "Bitte geben Sie einen Benutzernamen ein"},
        {English: "Please enter your full name (first name and last name, separated by a space)", German: "Bitte geben Sie Ihren vollständigen Namen ein (Vorname und Nachname, durch ein Leerzeichen getrennt)"},
        {English: "Please verify your phone number", German: "Bitte verifizieren Sie Ihre Telefonnummer"},
        {English: "Registration successful. You will be redirected to the login page. Please login with your new credentials.", German: "Registrierung erfolgreich. Sie werden zur Login-Seite weitergeleitet. Bitte melden Sie sich mit Ihren neuen Zugangsdaten an."},
        {English: "Register failed - phonenumber already exists.", German: "Registrierung fehlgeschlagen - Telefonnummer existiert bereits."},
        {English: "Register failed - heck your credentials or contact the website admin.", German: "Registrierung fehlgeschlagen - bitte Eingabedaten überprüfen oder Website Admin kontaktieren."},
    	{English: "Passwords do not match", German: "Passwörter stimmen nicht überein"},
        {English: "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, a number and one special character", German: "Passwort muss mindestens 8 Zeichen, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten"},
        {English: "Please enter a valid email address", German: "Bitte geben Sie eine gültige E-Mail-Adresse ein"},
        {English: "Please enter a valid phone number", German: "Bitte geben Sie eine gültige Telefonnummer ein"},
        {English: "Please verify your phone number", German: "Bitte verifizieren Sie Ihre Telefonnummer"},
        {English: "Passwords do not match", German: "Passwörter stimmen nicht überein"},
        {English: "Email address too long (Max. 50 Characters)", German: "E-Mail-Adresse zu lang (Max. 50 Zeichen)"},

        /* TwoFA.tsx */
        {English: "Please enter your Phone number", German: "Bitte geben Sie Ihre Telefonnummer ein"},
        {English: "Phone number", German: "Telefonnummer"},
        {English: "Send SMS", German: "SMS senden"},
        {English: "SMS send to:", German: "SMS gesendet an:"},
        {English: "Check code", German: "Code überprüfen"},
        {English: "If you have not received the code, you can request a new one.", German: "Wenn Sie den Code nicht erhalten haben, können Sie einen neuen anfordern."},
        {English: "Request new code", German: "Neuen Code anfordern"},
        {English: "Loading...", German: "Lädt..."},
        {English: " verified.", German: " verifiziert."},
        {English: "Please enter a valid code! (6 digits)", German: "Bitte geben Sie einen gültigen Code ein! (6 Ziffern)"},
        {English: "Wrong code! You have ", German: "Falscher Code! Sie haben noch "},
        {English: " tries left.", German: " Versuche übrig."},
        {English: "TWOFA BSMS Expired - Failed to validate the Code. - Most likely the Form was not correctly filled or the number of attempts ran out.", German: "TwoFA BSMS abgelaufen - Code konnte nicht verifiziert werden. - Wahrscheinlich wurde das Formular nicht korrekt ausgefüllt oder die Anzahl der Maximalversuche wurde überschritten."},
        {English: "Error occurred please contact your website admin. Errorcode: ", German: "Ein Fehler ist aufgetreten. Bitte kontaktieren Sie Ihren Website Admin. Fehlercode: "},
        {English: "2FA", German: "2FA"},
        {English: "2FA Settings", German: "2FA Einstellungen"},


        /* Dashboard.tsx */
        {English: "Dashboard", German: "Übersicht"},
        {English: "Hello", German: "Hallo"},
        {English: "Your Budget is", German: "Ihr Guthaben beträgt"},
        {English: "Sorry something went wrong. The site was unable to request the current Budget.", German: "Leider ist etwas schiefgelaufen. Das aktuelle Guthaben konnte nicht abgerufen werden."},
        {English: "First steps", German: "Erste Schritte"},
        {English: "Choose your sides and get started", German: "Wählen Sie Ihre Seiten und starten Sie"},
        {English: "Here you can send SMS and check your invoices, statistics, etc.", German: "Hier können Sie SMS senden und Ihre Rechnungen, Statistiken, etc. überprüfen."},
        {English: "Check out all of our features, by using the sidebar.", German: "Sie finden alle unsere Funktionen über die Seitenleiste."},
        {English: "Using the Sidebar you can check out your statistics and reports, send a sms. get your invoices and much more.", German: "Über die Seitenleiste können Sie Ihre Statistiken abrufen, eine SMS senden, Rechnungen einsehen und vieles mehr."},
        {English: "Using the Dashboard tab you get back to this page.", German: "Über den Menüpunkt Übersicht kommen Sie wieder auf diese Seite."},
        {English: "On the Reports tab you can show a complete table of your sms send statistics.", German: "Über den Berichte Menüpunkt können Sie eine komplette Tabelle Ihrer SMS Versand Statistiken einsehen."},
        {English: "Here you can try out and test our SMS sending interface.", German: "Hier können Sie unser SMS Versand Interface testen."},
        {English: "List all your invoices in a table and download them by clicking on a table row.", German: "Listen Sie alle Ihre Rechnungen in einer Tabelle auf und laden Sie diese herunter, indem Sie auf eine Tabellenzeile klicken."},
        {English: "Recharge your balance by generating a invoice.", German: "Laden Sie Ihr Guthaben auf, indem Sie eine Rechnung generieren."},
        {English: "Start using our Two Factor Authentification. Here you can setup all the parameters to use our Two Factor Authentification Component.", German: "Nutzen Sie unsere Zwei-Faktor-Authentifizierung. Hier können Sie alle Parameter einstellen, um unser Zwei-Faktor-Authentifizierungs-Modul zu verwenden."},
        {English: "The Profile tab shows you all your Profile Data aswell with your API Key and additional information.", German: "Der Profil Menüpunkt zeigt Ihnen alle Ihre Profildaten sowie Ihren API Key und zusätzliche Informationen."},
        {English: "By using the account manager you can manage your sub accounts. You can create, edit and delete sub accounts. Also it is possible to easily switch to a sub account.", German: "Mit dem Kontenmanager können Sie Ihre Unterkonten verwalten. Sie können Unterkonten erstellen, bearbeiten und löschen. Außerdem ist es möglich zu einem Unterkonto zu wechseln."},


        /* Sidebar.tsx */
        {English: "Sorry something went wrong. The site was unable to request the current API Version.", German: "Leider ist etwas schiefgelaufen. API Version konnte nicht abgefragt werden."},
        {English: "Reports", German: "Berichte"},
        {English: "Send SMS", German: "SMS senden"},
        {English: "Invoices", German: "Rechnungen"},
        {English: "Balance", German: "Guthaben"},
        {English: "Logout", German: "Abmelden"},
        {English: "not available", German: "nicht verfügbar"},
        {English: "My Profile", German: "Mein Profil"},
        {English: "Webversion", German: "Webversion"},
        {English: "API Version: ", German: "API Version: "},
        {English: "Back to Mainaccount", German: "Zurück zum Hauptkonto"},

        
        /* balance.tsx */
        {English: "Balance", German: "Guthaben"},
        {English: "Sorry something went wrong. The site was unable to request the current Budget.", German: "Leider ist etwas schiefgelaufen. Das aktuelle Guthaben konnte nicht abgerufen werden."},
        {English: "Add credits to your balance", German: "Guthaben aufladen"},
        {English: "How much money would you like to add to your balance?", German: "Wie viel Geld möchten Sie Ihrem Guthaben hinzufügen?"},
        {English: "Please enter a valid amount!", German: "Bitte geben Sie einen gültigen Betrag ein!"},
        {English: "Your current budget is: ", German: "Ihr aktuelles Guthaben beträgt: "},
        {English: "(Seperator: ',' - Currency: €/EUR)", German: "(Trennzeichen: ',' - Währung: €/EUR)"},
        {English: "Campaign", German: "Kampagne"},
        {English: "Please enter a valid campaign name! The campaign name can not be empty!", German: "Bitte geben Sie einen gültigen Kampagnennamen ein! Der Kampagnenname darf nicht leer sein!"},
        {English: "Please do not use more than 20 characters for your campaign", German: "Bitte verwenden Sie nicht mehr als 20 Zeichen für Ihren Kampangennamen!"},
        {English: "Charge", German: "Aufladen"},
        {English: "Budget inserted: ", German: "Guthaben eingegeben: "},
        {English: "Sorry, the service was unable to create a invoice for your current request. It might be that the campain you entered contains invalid characters. Please avoid using special characters like: /, \\, *, ?, !, #, $, %, &, (, ), [, ], {, }, <, >, |, +, =, ~, `, ^, @, ', \"", German: "Leider ist etwas schiefgelaufen. Die Rechnung konnte nicht erstellt werden. Wahrscheinlich enthält die Kampagne ungültige Zeichen. Bitte verwenden Sie keine Sonderzeichen wie: /, \\, *, ?, !, #, $, %, &, (, ), [, ], {, }, <, >, |, +, =, ~, `, ^, @, ', \""},
        {English: "Here you can insert your campaign name", German: "Hier können Sie Ihren Kampagnennamen eingeben"},
        {English: "(Maximum 20 Characters)", German: "(Maximal 20 Zeichen)"},
        {English: "Charge via Invoice", German: "Aufladen per Rechnung"},
        {English: "Charge via PayPal", German: "Aufladen per PayPal"},
        {English: "Please enter a valid amount or enter a valid campaign name! The campaign name can not be empty! Please do not use more than 20 characters!", German: "Bitte geben Sie einen gültigen Betrag oder einen gültigen Kampagnennamen ein! Der Kampagnenname darf nicht leer sein! Bitte verwenden Sie nicht mehr als 20 Zeichen!"},
        {English: "Proceed with Payments", German: "Bezahlvorgang fortsetzen"},
        {English: "€ (incl. Taxes)", German: "€ (inkl. Mehrwertsteuer)"},
        {English: "You will be charged: ", German: "Es wird Ihnen berechnet: "},
        {English: "Charge via PayPal", German: "Aufladen per PayPal"},
        {English: "Total: ", German: "Brutto: "},
        {English: "Taxes: ", German: "Mehrwertsteuer: "},
        {English: "PayPal Fees: ", German: "PayPal Gebühren: "},
        {English: "Budget could not be loaded.", German: "Guthaben konnte nicht geladen werden."},
        {English: "You have to pay", German: "Zu zahlender Betrag"},
        {English: "Current invoice amount", German: "Aktueller Rechnungsbetrag"},

        /* invoice.tsx */
        {English: "January", German: "Januar"},
        {English: "February", German: "Februar"},
        {English: "March", German: "März"},
        {English: "April", German: "April"},
        {English: "May", German: "Mai"},
        {English: "June", German: "Juni"},
        {English: "July", German: "Juli"},
        {English: "August", German: "August"},
        {English: "September", German: "September"},
        {English: "October", German: "Oktober"},
        {English: "November", German: "November"},
        {English: "December", German: "Dezember"},
        {English: "Sorry something went wrong. The site was unable to request the current Invoices.", German: "Leider ist etwas schiefgelaufen. Die aktuellen Rechnungen konnten nicht abgerufen werden."},
        {English: "Sorry something went wrong. The site was unable to request the current Invoice.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Rechnung konnte nicht abgerufen werden."},
        {English: "Name", German: "Name"},
        {English: "Month", German: "Monat"},
        {English: "Year", German: "Jahr"},
        {English: "Filename", German: "Dateiname"},
        {English: "Date created", German: "Erstellt am"},

        
        /* Login.stx */
        {English: "Login failed - check your credentials.", German: "Login fehlgeschlagen - bitte Daten überprüfen."},
        {English: "Login", German: "Login"},
        {English: "Username", German: "Benutzername"},
        {English: "Password", German: "Passwort"},
        {English: "Don't have a login? Register here!", German: "Kein Konto? Erstellen Sie eins hier!"},
        {English: "Login with Username and Password.", German: "Login mit Benutzername und Passwort."},
        {English: "If you have an account without a phonenumber you can still login using the old method.", German: "Wenn Sie ein Konto ohne Telefonnummer haben, können Sie sich immer noch mit der alten Methode anmelden."},
        {English: "Register/Login with Phonenumber.", German: "Registrieren/Anmelden mit Telefonnummer."},
        {English: "If you already have an account with a phonenumber you can easily login with a one time password. If you dont have an account yet an account will be created using your phonenumber.", German: "Wenn Sie bereits ein Konto mit Telefonnummer haben, können Sie sich einfach mit einem Einmalpasswort anmelden. Wenn Sie noch kein Konto haben, wird ein Konto mit Ihrer Telefonnummer erstellt."},

        /* register */
        {English: "Register", German: "Konto erstellen"},
        {English: "Please check your E-Mails", German: "Bitte ihre E-Mails überprüfen"},
        {English: "Password - min. 8 characters", German: "Passwort - min. 8 Zeichen"},
        {English: "Firstname", German: "Vorname"},
        {English: "Lastname", German: "Nachname"},
        {English: "Phonenumber", German: "Telefonnummer"},
        {English: "E-Mail", German: "E-Mail"},
        {English: "Already got a login? - Click here!", German: "Konto bereits vorhanden? - Klicken Sie hier!"},

        /* send sms */
        {English: "Number is too small. Min. required numbers is: ", German: "Die eingegebene Nummer ist zu klein. Mindestanzahl der Zeichen: "},
        {English: "You can insert your message text here...", German: "Hier können Sie eine Nachricht verfassen..."},
        {English: "Number is too big. Max. numbers is: ", German: "Die eingegebene Nummer ist zu groß. Maximalanzahl der Zeichen: "},
        {English: "Send a SMS to a Phonenumber", German: "SMS an eine Telefonnummer senden"},
        {English: "Inserted phone number: ", German: "Eingegebene Telefonnummer: "},
        {English: "Message Text", German: "Nachrichtinhalt"},
        {English: "Send", German: "Senden"},
        {English: "Please insert a valid phonenumber!", German: "Bitte geben Sie eine gültige Telefonnummer ein!"},
        {English: "Please insert a valid message text!", German: "Bitte geben Sie eine gültige Nachricht ein!"},
        {English: "no number / number not valid", German: "Keine Nummer / Nummer nicht aktzeptiert"},
        {English: "Sending failed! Please check your Balance or inform the support.", German: "Senden fehlgeschlagen! Bitte überprüfen Sie Ihr Guthaben oder kontaktieren Sie den Support."},
        {English: "Message was send. The reference number is: ", German: "Nachricht wurde versendet. Die Referenznummer lautet: "},
        {English: "(max. 160 Chars)", German: "(max. 160 Zeichen)"},

        /* Report Navigation */
        {English: "Send Statistic", German: "Versandstatistik"},
        {English: "Single Statistic", German: "Einzelstatistik"},
        /* Send statistic */
        {English: "x-Code", German: "x-Code"},
        {English: "x-code", German: "x-code"},
        {English: "Search", German: "Suchen"},
        {English: "Search...", German: "Suchen..."},
        {English: "Loading Data...", German: "Lade Daten..."},
        {English: "Due to data protection, the phone number is not fully displayed.", German: "Aus Datenschutzgründen wird die Telefonnummer nicht vollständig angezeigt."},
        
        {English: "Sorry something went wrong. The site was unable to request the current Statistic.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Statistik konnte nicht abgerufen werden."},
        /* Statistics Table */
        {English: "ID", German: "ID"},
        {English: "TID", German: "TID"},
        {English: "State", German: "Status"},
        {English: "your code", German: "dein Code"},
        {English: "IP", German: "IP"},
        {English: "phone", German: "Telefonnummer"},
        {English: "created", German: "Erstellt am"},
        {English: "sent", German: "versendet"},

        /* Profile */
        {English: "Profile", German: "Profil"},
        {English: "Edit", German: "Bearbeiten"},
        {English: "Save", German: "Speichern"},
        {English: "Cancel", German: "Abbrechen"},
        {English: "Show", German: "Anzeigen"},
        {English: "Hide", German: "Verstecken"},
        {English: "API-Key", German: "API-Key"},
        {English: "Account Type", German: "Kontotyp"},
        {English: "Change Password", German: "Passwort ändern"},
        {English: "New Password", German: "Neues Passwort"},
        {English: "Old Password", German: "Altes Passwort"},
        {English: "Save new Password", German: "Neues Passwort speichern"},
        {English: "Confirm Password", German: "Passwort bestätigen"},
        {English: "Password must contain at least 8 characters, one uppercase letter and one special character", German: "Passwort muss mindestens 8 Zeichen, einen Großbuchstaben und ein Sonderzeichen enthalten"},
        {English: "Be careful! If you use server scripts created by yourself you need to change the password there aswell.", German: "Vorsicht! Wenn Sie selbst erstellte Serverskripte verwenden, müssen Sie auch dort das Passwort ändern."},

        /* Account Manager */
        {English: "Account Manager", German: "Kontenverwaltung"},
        {English: "Account Manager of", German: "Kontenverwaltung von"},
        {English: "Account", German: "Konto"},
        {English: "Create new Account", German: "Neues Konto anlegen"},
        {English: "new account name", German: "neuer Kontoname"},
        {English: "Tag", German: "Tag"},
        {English: "Delete", German: "Löschen"},
        {English: "Login with subaccount", German: "Mit Unterkonto anmelden"},
        {English: "Account saved", German: "Konto gespeichert"},
        {English: "Account deleted", German: "Konto gelöscht"},
        {English: "Account created", German: "Konto erstellt"},
        {English: "Are you sure you want to delete this account?", German: "Sind Sie sicher, dass Sie dieses Konto löschen möchten?"},
        {English: "Are you sure you want to login with this account?", German: "Sind Sie sicher, dass Sie sich mit diesem Konto anmelden möchten?"},
        {English: "You are currently using ", German: "Sie verwenden derzeit "},
        {English: " out of ", German: " von "},
        {English: " subaccounts", German: " Unterkonten"},
        {English: "new account", German: "neues Konto"},
        {English: "copy", German: "kopieren"},
        {English: "delete", German: "löschen"},
        {English: "Sender identification", German: "Absenderkennung"},
        {English: "Password can not be empty. A password must contain at least 8 characters, one uppercase letter and one special character", German: "Passwort darf nicht leer sein. Ein Passwort muss mindestens 8 Zeichen, einen Großbuchstaben und ein Sonderzeichen enthalten"},
        {English: "Sender identification can not be empty", German: "Absenderkennung darf nicht leer sein"},
        {English: "Generate a random password", German: "Zufälliges Passwort generieren"},
        {English: "Copy to clipboard", German: "In die Zwichenablage kopieren"},
        {English: "Here you can change the password of the subaccount. The current password will not be displayed here and when changed will be overwritten.", German: "Hier können Sie das Passwort des Unterkontos ändern. Das aktuelle Passwort wird hier nicht angezeigt und wird beim Ändern überschrieben."},
        {English: "Username of the subaccount. This can not be changed.", German: "Benutzername des Unterkontos. Dies kann nicht geändert werden."},
        {English: "Sender Identification that will be displayed for the reciever.", German: "Absenderkennung, die für den Empfänger angezeigt wird."},
        {English: "API Keys can be used to send Messages using this specific account. This key can not be changed and is individualy generated for each subaccount.", German: "API-Keys können verwendet werden, um Nachrichten mit diesem spezifischen Konto zu senden. Dieser Schlüssel kann nicht geändert werden und wird individuell für jedes Unterkonto generiert."},

        /* EVENTS */
        /* Easter 24 */
        /* Login */
        {English: "Welcome to the Easter 2024 event!", German: "Willkommen zum Osterevent 2024!"},
        {English: "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Please login to your account to see the special offers we have prepared for you. We wish you a lot of fun and a happy Easter!", German: "Herzlich Willkommen zum Osterevent 2024! Wir freuen uns, Sie zu unserem Event begrüßen zu dürfen. Bitte melden Sie sich an, um die speziellen Angebote zu sehen, die wir für Sie vorbereitet haben. Wir wünschen Ihnen viel Spaß und ein frohes Osterfest!"},
        /* Register */
        {English: "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Please register to your account to see the special offers we have prepared for you. We wish you a lot of fun and a happy Easter!", German: "Herzlich Willkommen zum Osterevent 2024! Wir freuen uns, Sie zu unserem Event begrüßen zu dürfen. Bitte registrieren Sie sich, um die speziellen Angebote zu sehen, die wir für Sie vorbereitet haben. Wir wünschen Ihnen viel Spaß und ein frohes Osterfest!"},
        /* Dashboard */
        {English: "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Check out our new platform version and our new Two Factor Authentification. We wish you a lot of fun and a happy Easter!", German: "Herzlich Willkommen zum Osterevent 2024! Wir freuen uns, Sie zu unserem Event begrüßen zu dürfen. Schauen Sie sich unsere neue Plattformversion und unsere neue Zwei-Faktor-Authentifizierung an. Wir wünschen Ihnen viel Spaß und ein frohes Osterfest!"},
    
        /* Sign up */
        {English: "Account created ", German: "Konto erstellt "},
        {English: "Your account has been created successfully. You can now login to your account.", German: "Ihr Konto wurde erfolgreich erstellt. Sie können sich nun anmelden."},
    
        /* other */
        {English: " ", German: " "},
        {English: "", German: ""},

        /* Error Messages */
        {English: "Count of allowed subaccounts reached (SMS23112204)", German: "Maximale Anzahl an Unterkonten erreicht (SMS23112204)"},

        /* Two Factor Authentification */
        {English: "Two Factor Authentification", German: "Zwei-Faktor-Authentifizierung"},
        {English: "Please enter a label for the site", German: "Bitte geben Sie einen Namen für die Seite ein"},
        {English: "Please select a 2FA type", German: "Bitte wählen Sie einen 2FA Typ aus"},
        {English: "Please enter a Captcha sitekey", German: "Bitte geben Sie einen Captcha Website Schlüssel ein"},
        {English: "Please enter a Captcha secretkey", German: "Bitte geben Sie einen Captcha geheimen Schlüssel ein"},
        {English: "Please select a Captcha type", German: "Bitte wählen Sie einen Captcha Typ aus"},
        {English: "Please enter at least one domain", German: "Bitte geben Sie mindestens eine Domain ein"},
        {English: "My Websites Label", German: "Name meiner Website"},
        {English: "Label", German: "Name"},
        {English: "TwoFA Type", German: "2FA Typ"},
        {English: "Captcha Keys", German: "Captcha Schlüssel"},
        {English: "Captcha Type", German: "Captcha Typ"},
        {English: "Domains", German: "Domains"},
        {English: "www.exampledomain.com", German: "www.beispielwebsite.de"},
        {English: "Add Domain", German: "Domain hinzufügen"},
        {English: "Add", German: "Hinzufügen"},
        {English: "Remove Domain", German: "Domain entfernen"},
        {English: "Save this configuration", German: "Konfiguration speichern"},
        {English: "Reset all recend configuration changes", German: "Alle Änderungen zurücksetzen"},
        {English: "Choose the sites you want to edit", German: "Wählen Sie die Website aus, die Sie bearbeiten möchten"},
        {English: "No sites available", German: "Keine Websites verfügbar"},
        {English: "Add Entry", German: "Eintrag hinzufügen"},
        {English: "Delete Entry", German: "Eintrag löschen"},
        {English: "Statistics", German: "Statistik"},
        {English: "Settings", German: "Einstellungen"},
        {English: "new website", German: "neue Website"},
        {English: "Sorry something went wrong. The site was unable to request the current TwoFA Sites. Please try again later.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Website konnte nicht abgerufen werden. Bitte versuchen Sie es später erneut."},
        {English: "Sorry something went wrong. The site was unable to delete the TwoFA Site. Please try again later.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Website konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut."},
        {English: "Sorry something went wrong. The site was unable to save the new TwoFA Site. Please try again later.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Website konnte nicht gespeichert werden. Bitte versuchen Sie es später erneut."},
        {English: "Sorry something went wrong. The site was unable to update the TwoFA Site. Please try again later.", German: "Leider ist etwas schiefgelaufen. Die ausgewählte Website konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut."},
        {English: "Please select a TwoFA Site", German: "Bitte wählen Sie eine Website aus"},
        {English: "Optin (Mail)", German: "Optin (Mail)"},
        {English: "Optin (SMS)", German: "Optin (SMS)"},
        {English: "Optin (Mail + SMS)", German: "Optin (Mail + SMS)"},
        {English: "TwoFA (Mail)", German: "TwoFA (Mail)"},
        {English: "TwoFA (SMS)", German: "TwoFA (SMS)"},
        {English: "TwoFA Sites", German: "TwoFA Webseiten"},
        {English: "TwoFA (OAuth2)", German: "TwoFA (OAuth2)"},
        {English: "Secretkey", German: "Geheimer Schlüssel"},
        {English: "Sitekey", German: "Website Schlüssel"},
        {English: "ReCaptcha", German: "ReCaptcha"},
        {English: "HCaptcha", German: "HCaptcha"},
        {English: "Your current websiteconfiguration got saved!", German: "Ihre aktuelle Websitekonfiguration wurde gespeichert!"},
        {English: "Error while saving. Please try again later.", German: "Fehler beim Speichern. Bitte versuchen Sie es später erneut."},


        /* TwoFA Tutorial */
        {English: "Create a Site", German: "Website erstellen"},
        {English: "After they press the button, a form appears. In this form you need to enter your data, such as domains or captcha keys. After you save the page, the BerlinSMS TwoFA Keys will be automatically generated and displayed in the form.", German: "Nachdem Sie auf den Button gedrückt haben, erscheint ein Formular. In diesem Formular müssen Sie Ihre Daten, wie z.B. Domains oder Captcha Schlüssek, eingeben. Nachdem Sie die Seite gespeichert haben, werden die BerlinSMS TwoFA Schlüssel automatisch generiert und im Formular angezeigt."},
        {English: "Websitelabel", German: "Websitelabel"},
        {English: "The website label is the name of your configuration. This name is necessary to distinguish between different configurations.", German: "Der Websitename ist der Name Ihrer Konfiguration. Dieser Name ist notwendig, um zwischen verschiedenen Konfigurationen zu unterscheiden."},
        {English: "TwoFA Type", German: "TwoFA Typ"},
        {English: "Use the TwoFA type to select the verification method you want to add to your website. Currently supported are Optin(SMS) and Optin(Mail).", German: "Über den TwoFA Typ können Sie die Verifizierungsmethode auszuwählen, die Sie Ihrer Website hinzufügen möchten. Derzeit werden Optin(SMS) und Optin(Mail) unterstützt."},
        {English: "Captcha Keys", German: "Captcha Schlüssel"},
        {English: "You have to register the captcha keys independently via the respective provider. When registering a captcha, you will receive two keys. Once a sitekey and a secretkey. In order for our TwoFA component to protect you from automated requests, it is necessary to provide us with these two keys. Currently the captcha types ReCaptcha and HCaptcha are supported.", German: "Die Captcha Schlüssel müssen Sie über den jeweiligen Anbieter generieren. Bei der Registrierung eines Captchas erhalten Sie zwei Schlüssel. Einen Website Schlüssel und einen geheimen Schlüssel. Damit unsere TwoFA Komponente Sie vor automatisierten Anfragen schützen kann, ist es notwendig, uns diese beiden Schlüssel zur Verfügung zu stellen. Derzeit werden die Captcha Typen ReCaptcha und HCaptcha unterstützt."},
        {English: "TwoFA Keys", German: "TwoFA Schlüssel"},
        {English: "After you have filled in all fields (except this one), you can save your configuration. Afterwards the TwoFA Keys will be generated and displayed at this point. You can then use these to integrate our component on your website.", German: "Nachdem Sie alle Felder (außer dieses) ausgefüllt haben, können Sie Ihre Konfiguration speichern. Anschließend werden die TwoFA Schlüssel generiert und an dieser Stelle angezeigt. Diese können Sie dann verwenden, um unsere Komponente auf Ihrer Website zu integrieren."},
        {English: "Domains", German: "Domains"},
        {English: "Domains are the actual web addresses under which your component will run. For each request, the system checks whether the requesting domain corresponds to one of the stored domains. A distinction must also be made here between subdomains (e.g. app.berlinsms.de, twofa.berlinsms.de, ...). For local testing, domains such as localhost also work.", German: "Domains sind die eigentlichen Webadressen, unter denen Ihre Komponente laufen wird. Bei jeder Anfrage wird geprüft, ob die anfragende Domain einer der gespeicherten Domains entspricht. Auch hier muss zwischen Subdomains (z.B. app.berlinsms.de, twofa.berlinsms.de, ...) unterschieden werden. Für lokale Tests funktionieren auch Domains wie localhost."},
        {English: "Use our App to get Statistics and other great features. Use this site to create and implement a Two Factor Authentification to your own website!", German: "Nutzen Sie unsere App, um Statistiken und andere großartige Funktionen zu erhalten. Nutzen Sie diese Seite, um eine Zwei-Faktor-Authentifizierung für Ihre eigene Website zu erstellen und zu implementieren!"},
        {English:"After saving your configuration, you can integrate the 2FA component on your website using the two-factor authentication key shown here. Always keep the secret key to yourself and only enter the website key on your website.", German:"Nach dem Speichern ihrer Konfiguration können Sie mithilfe der hier angezeigten Zwei Faktor Authentifizierung Schlüssel die 2FA Komponente auf Ihrer Website integrieren. Behalten Sie den geheimen Schlüssel immer für sich und geben Sie auf Ihrer Website nur den Website-Schlüssel an."}
    ]

const _translate = (text: string, toEnglish: Boolean = false) => {
    defaultLanguage = cookiehandler.getCookie("language");
    if(defaultLanguage === null || defaultLanguage === 'undefined' || defaultLanguage === "") defaultLanguage = "Ger";
    var searchvar = 0;
    if(toEnglish){
        if(defaultLanguage==="Ger"){
            for(searchvar = 0; searchvar < LanguagesList.length; searchvar++){
                if(LanguagesList[searchvar].German === text) return LanguagesList[searchvar].English;
            }
        }
    } else {
        if(defaultLanguage==="Ger"){
            for(searchvar = 0; searchvar < LanguagesList.length; searchvar++){
                if(LanguagesList[searchvar].English === text) return LanguagesList[searchvar].German;
            }
        }
    }
    // console.log("Translation not found: " + text);
    return text;
}

export const _getEventText = (event: string, context: string, element: string): string => {
    if(event === "ostern24"){
        if(context === "login") {
            if(element === "header") {
                return "Welcome to the Easter 2024 event!";
            } else if(element === "textspan") {
                return "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Please login to your account to see the special offers we have prepared for you. We wish you a lot of fun and a happy Easter!";
            }
        } else if(context === "register") {
            if(element === "header") {
                return "Welcome to the Easter 2024 event!";
            } else if(element === "textspan") {
                return "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Please register to your account to see the special offers we have prepared for you. We wish you a lot of fun and a happy Easter!";
            }
        } else if(context === "dashboard") {
            if(element === "header") {
                return "Welcome to the Easter 2024 event!";
            } else if(element === "textspan") {
                return "Greetings from the Easter 2024 event! We are happy to welcome you to our event. Check out our new platform version and our new Two Factor Authentification. We wish you a lot of fun and a happy Easter!";
            }
        }
    } else if (event === "signup-completed") {
        if(context === "login") {
            if(element === "header") {
                return "Account created";
            } else if(element === "textspan") {
                return "Your account has been created successfully. You can now login to your account.";
            }
        }
    }

    return "";
}

export const convertMStoDateString = (ms: number) => {
    var date = new Date(ms);
    return date.getDate().toString() + ". " + (_translate(assignMonthtext(date.getMonth()+1))) + " " + date.getFullYear().toString();
}

export const assignMonthtext = (monthNumber: number) => {
    if(monthNumber === 1) return "January";
    else if(monthNumber === 2) return "February";
    else if(monthNumber === 3) return "March";
    else if(monthNumber === 4) return "April";
    else if(monthNumber === 5) return "May";
    else if(monthNumber === 6) return "June";
    else if(monthNumber === 7) return "July";
    else if(monthNumber === 8) return "August";
    else if(monthNumber === 9) return "September";
    else if(monthNumber === 10) return "October";
    else if(monthNumber === 11) return "November";
    else if(monthNumber === 12) return "December";
    else return monthNumber.toString();
}
export const assignMonthNumber = (monthText: String) => {
    if(monthText === "January") return 1;
    else if(monthText === "February") return 2;
    else if(monthText === "March") return 3;
    else if(monthText === "April") return 4;
    else if(monthText === "May") return 5;
    else if(monthText === "June") return 6;
    else if(monthText === "July") return 7;
    else if(monthText === "August") return 8;
    else if(monthText === "September") return 9;
    else if(monthText === "October") return 10;
    else if(monthText === "November") return 11;
    else if(monthText === "December") return 12;
    else return 0;
}

export default _translate;